
@import "src/theme";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .documentsContainer {
    display: flex;
    flex-direction: row;
    ;
    gap: 20px;
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
    color: $primaryDark;
  }
