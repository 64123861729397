.optionsContainer {
    margin-top: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    background-color: white;
    max-height: 150px;
    overflow: auto;
    position: absolute;

}
.list {
    display: flex;
    list-style: none;
    padding: 5px;
    flex-direction: column;
    gap: 0.5rem;
}
.option {
    cursor: pointer;
    border-bottom: 1px solid #f3f4f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
    &:hover {
        background-color: #f5f5f5;
        border-radius: 0.5rem;
    }
    &:focus {
        background-color: #f5f5f5;
        border-radius: 0.5rem;
    }
}
.optionDefault {
    text-align: center;
    font-style: italic;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
    color: #6b7280;
}