@import "src/theme";

.select {
  width: 100%;
  // height:100%;
  display: block;
  flex: 1;
  border: 3px solid white;
  border-radius: 10px;
  padding: 8px 0px 8px 0px;
  background: white;
  outline: none;
  font-size: 21px;
  &:focus {
    border: 3px solid white;
    background: white;
  }
  &:hover:not(:disabled) {
    box-shadow: 0px 0px 4px -3px black;
  }
  &:disabled {
    background: rgb(223, 223, 223);
    border-color: rgb(223, 223, 223);
  }
}
.hasNoValue {
  color: $evenDarkerGray;
  option {
    color: black;
    &:disabled {
      color: $evenDarkerGray;
    }
  }
}
.selectWithStartIcon {
  padding: 8px 0px 8px 60px;
}
.selectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin-bottom: 12.5px;
  // margin-top: 12.5px;
  position: relative;
}
.icon {
  display: inline-block;
  top: 20px;
  left: 20px;
  color: $blue;
  position: absolute;
  width: 24px;
  height: 27px;
  text-align: center;
  > svg {
    width: 100%;
    height: 100%;
  }
}

.label {
  margin-left: 15px;
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  position: absolute;
  top: -13px;
}
.autofilled {
  //border: 2px dashed rgb(255, 96, 123);
}
.autofilledLabel {
  color: rgb(255, 96, 123);
}
