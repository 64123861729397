.button {
    &:hover {
        background-color: #e2e8f0; // equivalent to bg-stone-200
        color: #ef4444; // equivalent to text-red-500
    }
    display: flex;
    border: none;
    cursor: pointer;
    padding-left: 0.25rem; // equivalent to px-1
    padding-right: 0.25rem; // equivalent to px-1
}
.chip {
    border-radius: 0.375rem; // equivalent to rounded-md
    display: flex;
    flex: none;
    justify-content: space-between;
    align-items: center;
    gap: 5px; // equivalent to gap-2
    color: #6b7280; // equivalent to text-[#6b7280]
    font-size: 18px; // equivalent to text-sm
    height: 100%;
    font-weight: 500; // equivalent to font-medium
    margin: 5px; // equivalent to p-1
    padding: 5px;
}
.chipHighlight {
    background-color: #1f2937; // equivalent to bg-gray-800
    color: #ffffff; // equivalent to text-white
}
.chipNormal {
    background-color: #f3f4f6; // equivalent to bg-gray-100
    color: #6b7280
}
.label {
    padding: 0.25rem 0.5rem;
}