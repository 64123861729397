@import "src/theme";

.container {
  border: 3px solid $gray;
  border-radius: 5px;
  height: 100%;
  position: relative;
}
.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  color: $primaryDark;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
}
.tableTitleSecondary {
  font-size: 10px;
  font-weight: 500;
  color: $blue;
  padding-left: 5px;
}
