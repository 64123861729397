@import "src/theme";

.btn {
  padding: 0 10px;
  border-radius: 10px;
  color: #fff;
  border: none;
  width: 100%;
  height: 36px;
  font-weight: 700;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  &:hover {
    box-shadow: 0px 0px 6px -2px black;
  }
}

.btn.gradient {
  color: #fff;
  background: $primaryGradientColor;
}

.btn.pink {
  color: #fff;
  background: $pink;
}
.btn.white {
  border: solid 3px transparent;
  background-image: $primaryGradientColor, $primaryGradientColor;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  &:hover {
    box-shadow: 0px 0px 6px -2px black, 2px 1000px 1px #fff inset;
  }
  > span {
    background: $primaryGradientColor;
    display: block;
    width: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.btn.blue {
  color: #fff;
  background: $blue;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.flashing {
  animation: flash 1s ease-in-out 10;
}
