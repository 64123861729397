html, body {
  margin: 0;
  padding: 0;
  font-family:'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}
/* * {
  box-sizing: border-box;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #6b97f6 transparent;
    /* scrollbar-color: #6b97f6 #a5aec1; */
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  
  *::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 5px #a5aec1;
    /* border-radius: 5px; */
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;    
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #6b97f6;
    border-radius: 5px;
    border: 0px solid #ffffff;
  }