@import "src/theme";

.container {
  padding-left: 20px;
  padding-right: 20px;
}
.applicationProgress {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  white-space: nowrap;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  padding: 0px 20px;
}

.applicationSummary {
  white-space: nowrap;
  font-weight: 500;
  font-size: 20px;
  padding: 0px 20px;
}

.gridItem {
  box-sizing: border-box;
}
