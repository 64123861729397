
@import "src/theme";


.container {
    border: 3px solid $gray;
    border-radius: 5px;
  

    &:focus {
        margin: -2px !important;
        border: 2px solid $blue;
        animation: blink 1s steps(100, start) 2;
      }
}
  @keyframes blink {
    50% {
      border-color: #fff;
    }
  }