@import "src/theme";

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  padding: 0px 10px;
}

.container {
  width: 100%;
  flex-direction: row;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.flexItem {
  cursor: pointer;
  gap: 10px;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  background: $darkGray;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
}

.approved {
  background-color: $success;
}
.pending {
  background-color: $warning;
}

.rejected {
  background-color: $pink;
}

.stepperContainer {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.stepperItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
}

.stepTitle {
  position: absolute;
  font-weight: bold;
  top: 50px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
.line {
  flex: 1;
}
.stepIcon {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
}
.status {
  background-color: $darkGray;
  font-weight: 700;
  font-size: 18px;
  padding: 5px 0px;
  border-radius: 5px;
  justify-content: center;
  width: 100%;
  color: rgb(255, 255, 255);
  max-width: 250px;
  white-space: nowrap;
  text-align: center;
}

.statusContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 2px;
  border: 1px solid;
  border-radius: 6px;
  border-color: #f3f3f3;
}

.awaiting {
  background-color: $darkGray;
}

.pending {
  background-color: $warning;
}
.rejected {
  background-color: $pink;
}
.resolved {
  background-color: $success;
}

.dropzone {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview {
  box-sizing: border-box;
  height: 100%;
}
.labelStyle {
  font-size: 24px !important;
}

.selectedFile {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  padding: 10px !important;
}

.myTitleIssueNote {
  padding: 10px;
  background-color: #fff;
  border-radius: 9px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  margin-left: auto;
}
.otherTitleIssueNote {
  padding: 10px;
  background-color: $lightBlue;
  border-radius: 9px;
  font-size: 18px;
  word-wrap: break-word;
  font-weight: 600;
  color: #000;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.noteCreator {
  font-size: 12px;
  font-style: italic;
  color: $darkerGray;
  margin-left: auto;
  padding-right: 5px;
}

.noteContainer {
  width: 100%;
  height: 470px;
  overflow-y: auto;
  margin-bottom: 10px;
}
.previewDocumentTitle {
  font-size: 20px;
  font-weight: bold;
}

.previewDocumentContainer {
  padding: 12px !important;
}

.previewDocumentContainerList {
  height: 125px;
  overflow-y: auto;
}
.previewDocumentName {
  font-size: 12px;
  font-weight: 500;
}
.fileName {
  font-weight: bold;
  font-style: italic;
}
.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}
.limitContainer {
  display: flex;
  flex: 1;
  gap: 3px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 6px;
}
.limitContainerBorderOver {
  border: 2px solid;
  border-color: $pink;
  color: white;
  background-color: $pink;
  span {
    color: black;
  }
}
.limitContainerBorder {
  border: 2px solid;
  border-color: $warning;
  color: white;
  background-color: $warning;
  span {
    color: black;
  }
}

.important {
  font-weight: 800;
}
.tableTitleSecondary {
  font-size: 11px;
  font-weight: 500;
  color: $blue;
  padding: 0px 10px;
}
