@import "src/theme";
.greeting {
  font-size: 38px;
  font-family: $secondMain;
  color: $primaryDark;
}
.dashboardContainer {
  padding: 50px 0px 0px 0px;
  width: 100%;
}
.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.welcomeBanner {
  z-index: 0;
  background: $secondaryGradientColor;
  padding: 20px;
  height: 211px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  > h1 {
    font-size: 38px;
    line-height: 45px;
    font-weight: 700;
  }
  svg {
    position: absolute;
    right: 10px;
    top: -100px;
    width: 477px;
    height: 369px;
    z-index: 2;
  }
}
.button {
  margin-top: 54px;
  margin-left: 10px;
  left: 20px;
  bottom: 20px;
  width: 239.76px;
  height: 56.76px;
  background: $blue;
  border-radius: 5px;
  border: none;
  font-size: 19.4861px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
