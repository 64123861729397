@import "src/theme";


.container { 
    display: block; 
    position: relative;
    flex: 1;
width: 100%; 
  border-radius: 10px;
}
.inputContainer {
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
   
}

.input {
    min-width: 30%;
    flex: 1;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
    margin-left: 0.25rem;
    margin-top: 10px;
    margin-bottom: 10px;
    &::placeholder {
        font-size: 18px;
        font-family: sans-serif;
    }
  
    border-radius: 0.375rem;
    border: none;
    background: none;
    outline: none;
    padding: 0.125rem;
}
.label {
    text-transform: capitalize;
    margin-left: 15px;
    font-size: 12px;
    padding: 5px;
    font-weight: 500;
    position: absolute;
    top: -13px;
    z-index: 999;
  }
  .actionIcons {
    display: flex;
    margin-left: auto;
    gap: 5px;
    padding: 10px;
  }
