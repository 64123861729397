@import "src/theme";

.columnHeader {
  padding-left: 15px;
  font-weight: bold;
  line-height: 18px;
  flex-grow: 1;
  text-align: left;
  font-size: 16px;
}
.columnContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}


.footerRow {
  line-height: 20px;
  padding: 12px 0px;
  white-space: nowrap;
  flex-grow: 1;
  min-height: auto;
  background: #e6e4e4;
  border-radius: 6px;

  td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  td:last-child {
    border-radius: 0px 5px 5px 0px;
    text-align: center;
  }
  &:hover {
    background-color: $lightBlueSecondary;
    color: $evenDarkerGray;
    #icon {
      color: $evenDarkerGray;
    }
  }
}
.notFound {
  padding: 5px;
  text-align: center;
  font-size: 30px;
  min-height: 40px;
  color: #757575;
  background: $gray;
  line-height: 22px,
}
.tableContainer {
  width: 100%;
}

.tableHead {
  white-space: nowrap;
}

.tableTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: $primaryDark;
  padding-left: 5px;
}
.tableTitleSecondary {
  font-size: 10px;
  font-weight: 500;
  color: $blue;
  padding-left: 5px;
}
.table {
  line-height: 20px;
  padding: 0;
  min-height: 120px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  thead {
    tr {
      th {
        text-align: left;
        padding-left: 15px;
      }
    }
  }
  tbody {
    tr {
      padding: 12px 0px;
      white-space: nowrap;
      flex-grow: 1;
      min-height: auto;
      background: $gray;
      border-radius: 6px;
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
        padding-left: 15px;
        flex-grow: 1;
        text-align: left;
      }

      td:first-child {
        border-radius: 5px 0px 0px 5px;
      }
      td:last-child {
        border-radius: 0px 5px 5px 0px;
        text-align: center;
      }
      &:hover {
        background-color: $lightBlueSecondary;
        color: $evenDarkerGray;
        #icon {
          color: $evenDarkerGray;
        }
      }
    }
  }
}

