@import "src/theme";

.container {
  background: $gray;
  border-radius: 10px;
  height: auto;
  flex-direction: column;
  padding: 10px 0px !important;
  margin: 10px 0px !important;
  color: black;
  cursor: pointer;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 25px;
    // text-transform: capitalize;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}
.orContainer {
  align-items: center;
  background: white;
  font-size: 26px;
  width: 3px;
  margin: 50px 20px -10px 10px !important;
  div {
    position: relative;
    left: -18px;
    background-color: $gray;
    padding: 10px;
    width: 50px;
    font-weight: bold;
    color: #888;
  }
}
.prefillContainer {
  flex-direction: column !important;
  margin-left: 15px !important;
  margin-right: 30px !important;
  flex-grow: 1;
}
.prefillTitle {
  padding-top: 50px;
  color: $pink;
}
.item {
  margin: 5px 0px;
  border: 1px solid transparent;
  border: 2px dashed $gray;
  &:hover {
    border: 2px dashed #6b97f6;
    border-radius: 10px;
    background-color: white;
  }
  cursor: pointer;
}

.documentName {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

div.stepTitle {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  padding-left: 20px;
  svg {
    display: inline-block;
    margin-right: 30px;
    width: 50px;
  }
  svg:nth-child(2) {
    width: 100px;
  }
  p {
    display: inline-block;
  }
}
.active {
  color: $pink;
}
.completed {
  color: $blue;
}
.inactive {
  color: $darkGray;
}

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 40px;
  color: $primaryDark;
  padding-left: 30px;
}

.mainContainer {
  padding: 10px;
  // width: 100%
  position: relative;
}
.buttonContainer {
  display: flex;
  flex-direction: row;

  > button {
    width: 242px;
    margin-right: 20px;
  }
}

.completedIcon {
  margin-left: auto;
}
.skippedIcon {
  margin-left: auto;
  color: $darkGray;
}
.actions {
  margin-top: 30px;
  margin-bottom: 10px;
}

.stepContainer {
  padding: 0px 30px !important;
}
.scanText {
  font-size: 20px;
  font-weight: 600;
}
.scanContainer {
  padding: 20px I !important;
  margin: 10px !important;
  gap: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanItem {
  flex: 1;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 18px !important;
}
.scanTitle {
  color: $darkerGray;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
.scanText {
  color: $blue;
}
.scanButton {
  width: 80%;
  margin-top: auto;
}
.instructions {
  font-size: 18px;
  color: $blue;
  font-weight: 700;
  cursor: pointer;
}

ol li {
  padding: 8px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.lastSaved {
  top: 0px;
  position: sticky;
  color: #6b97f6;
  font-weight: 600;
  background: white;
  z-index: 999;
  padding: 5px;
  border-radius: 9px;
}
