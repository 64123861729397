
@import "src/theme";


.container {
    border: 3px solid $gray;
    border-radius: 5px;
    flex: 1;
}
.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;

  color: $primaryDark;
  padding-left: 10px;
  padding-top: 15px;
  margin-bottom: 5px;
  }
  .text {
    font-size: 18px;
    line-height: 22px;
  }