@import "src/theme";
.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000063;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialogContainer {
  z-index: 9999;
  background: #ffffff;
  padding: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  word-break: break-word;
}
.blue {
  color: $blue;
}
.default {
  color: $primaryDark;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-transform: capitalize;
  margin: 5px 0px;
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.dialogContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0px;
}

.dialogActions {
  display: flex;
  gap: 10px;
  justify-self: flex-end;
}
.end {
  align-self: flex-end;
}
.center {
  align-self: center;
  justify-content: center;
  width: 100%;
}
.space-between {
  align-self: flex-start;
  justify-content: space-between;
  width: 100%;
}
.sm {
  max-width: 640px;
}
.lg {
  max-width: 1200px;
}

.xs {
  max-width: 320px;
}
.md {
  max-width: 900px;
}
.spacing {
  margin: 20px 0px;
}
